
/**
 * @name: basic
 * @author: Gzm
 * @date: 2023-05-31 09:40
 * @description：基础信息组件
 * @update: 2023-05-31 09:40
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Message} from "element-ui";
import config from "@/config";
import {ITour} from "@/apis/travelManage/types";
import {queryTourLocationListApi} from "@/apis/travelManage";
import {checkPrice} from "@/constants/validators";

@Component({})
export default class ProductBasic extends Vue {
  @Prop(Object) data!: Object;
  config = config;
  // 新增修改表单
  modelForm: any = {
    // tourLocationIds: [],
    status: "1"
  }
  // 旅游地址国内
  tourSpecsValueListIn: any = []
  // 旅游地址国外
  tourSpecsValueListOut: any = []


  rules: any = {
    tourName: [
      {required: true, message: '请输入旅游名称', trigger: 'change'},
      {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'change'}
    ],
    tourTypes: [
      {required: true, message: '请选择旅游地区', trigger: 'change'}
    ],
    tourBannerPic: [
      {required: true, message: '请上传图片', trigger: 'change'}
    ],
    tourVideo: [
      {required: true, message: '请上传视频', trigger: 'change'}
    ],
    sort: [
      {required: true, message: '请选择排序', trigger: 'change'}
    ],
    tourStock: [
      {required: true, message: '请输入库存', trigger: 'change'}
    ],
    tourPrice: [
      { required: true, message: '请输入旅游售价', trigger: 'blur' },
      { validator: checkPrice, trigger: 'blur' },
    ],
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  /**
   * IUpload
   * 文件提交，成功或失败
   * @param res
   */
  onFileUpload(res: any) {
    if (res) {
      console.log(res, 'onFileUpload')
    }
  }

  /**
   * 上传视频前检验
   * @param file
   */
  beforeVideoUpload(file: File) {
    let fileSize = file.size / 1024 / 1024 < 50;   //控制大小  修改50的值即可
    if (
      [
        "video/mp4",
        "video/ogg",
        "video/flv",
        "video/avi",
        "video/wmv",
        "video/rmvb",
        "video/mov",
      ].indexOf(file.type) == -1     //控制格式
    ) {
      Message.error("请上传正确的视频格式");
      return false;
    }
    if (!fileSize) {
      Message.error("视频大小不能超过50MB");
      return false;
    }
  }

  /**
   * 下一步
   * @param number
   *
   */
  handleNext(tab: string) {
    console.log(tab)
    this.$emit('Next', tab)
  }

  getSite() {
    queryTourLocationListApi({types: 1,status:1}).then(e => {
      this.tourSpecsValueListIn = e
    })
    queryTourLocationListApi({types: 2,status:1}).then(e => {
      this.tourSpecsValueListOut = e
    })

  }

  radioTour(e: any) {
    //@ts-ignore
    if (this.modelForm.tourLocationIds.length > 0) {
      this.modelForm.tourLocationIds = []
    }
  }


  submitForm(formName: string) {
    //@ts-ignore
    this.$refs[formName].validate((valid) => {
      if (valid) {
        this.$emit('BasicData', this.modelForm, true)
      } else {
        this.$emit('BasicData', this.modelForm, false)
        return false;
      }
    });
  }

  created() {
    this.getSite()
  }

  get computedData(){
    return this.data;
  }

  @Watch('computedData')
  onChangeValue(newVal: any, oldVal: any){
    console.log(newVal,'监听数据')
    this.modelForm = newVal
    this.modelForm.tourTypes = this.modelForm.tourTypes + ""
    this.modelForm.status = this.modelForm.status + ""
    //@ts-ignore
    console.log(this.modelForm.tourLocationIds?.split(','))
//@ts-ignore
    this.modelForm.tourLocationIds =this.modelForm.tourLocationIds?.split(',')
  }
}
